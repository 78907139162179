<template>
    <div>
        <ListPage
            :datatableConfig="datatableConfig"
            :elementsAPIURL="`v4/resorts/ride-audiences`"
            :commonFilters="{ resort_uuid: $route.params.uuid }"
            :draggable="true"
            :routeConfigs="{
                prefix: 'resort-audiences',
            }"
        />
    </div>
</template>

<script>
import ListPage from '@/modules/Shared/Pages/sections/ListPage.vue'

export default {
    name: 'Audiences',
    components: {
        ListPage
    },
    data () {
        return {
            datatableConfig: [
                { 'type': 'text', 'field': 'key', head: this.$t("resorts.webcams.name"), 'searchable': false, 'sortable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("resorts.webcams.actions"), 'searchable': false, 'sortable': false }
            ],
        }
    },
}
</script>
