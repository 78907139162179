var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ListPage", {
        attrs: {
          datatableConfig: _vm.datatableConfig,
          elementsAPIURL: "v4/resorts/ride-audiences",
          commonFilters: { resort_uuid: _vm.$route.params.uuid },
          draggable: true,
          routeConfigs: {
            prefix: "resort-audiences"
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }